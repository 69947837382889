@tailwind base;

/* Write your own custom base styles here */
@layer base {
  html {
    font-size: 14px;
    @apply bg-background-default flex h-full text-gray-800;
  }
  body {
    @apply flex-1 flex overflow-hidden;
  }
  #root {
    @apply flex-1 flex flex-col;
  }
  h1 {
    @apply text-2xl font-bold mb-4;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-bold;
  }
  a {
    @apply underline;
  }
  input {
    @apply text-sm;
  }
  hr {
    @apply border-divider;
  }
}

/* Start purging... */
@tailwind components;
/* Stop purging. */

@layer components {
  /* Write your own custom component styles here */
  .empty-state {
    @apply italic opacity-75;
  }

  .caption {
    @apply text-sm opacity-75;
  }

  .hide-scrollbar {
    /*Firefox*/
    scrollbar-width: none;
  }
  .hide-scrollbar::-webkit-scrollbar {
    /*Chrome, Safari, Edge*/
    display: none;
  }

  .added-diff,
  .added-diff * {
    @apply bg-green-100 rounded;
  }

  .removed-diff,
  .removed-diff * {
    @apply bg-red-100 rounded;
  }
}

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

@layer utilities {
  .touch-action-none {
    touch-action: none;
  }

  .soft-blue-shadow {
    box-shadow: 12px 12px 30px rgba(230, 235, 255, 0.58);
  }
}

.draggable-source--is-dragging {
  @apply opacity-0;
}

.draggable-mirror {
  @apply z-50;
}

/* .nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  @apply bg-secondary-main;
  transition: all 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.active-nav-link::after {
  width: 100%;
  @apply bg-primary-main;
} */

.ql-editor {
  min-height: 100px;
}

.ql-container.ql-snow {
  @apply border border-divider;
}

.ql-toolbar.ql-snow {
  @apply border-none rounded-tl rounded-tr p-0;
}

.ql-container.ql-snow {
  @apply rounded-bl rounded-br;
}

#ql-toolbar #record-btn {
  @apply bg-background-paper border border-solid text-sm border-divider w-auto h-auto px-4 py-2 whitespace-nowrap inline-flex items-center hover:text-gray-800 focus:text-gray-800;
}

.ql-snow a {
  @apply text-gray-800;
}
